$color-primary: #BF0101;

// Add Bebas Neue font
@import url('https://fonts.googleapis.com/css?family=Bebas+Neue');
// Add Montserrat font with all weights



:root,
html {
    font-size: 10px;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;

    width: 100%;
    height: 100vh;
    font-size: 1.6rem;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5;
    background: linear-gradient(to left top, rgba(#44475a, 1), rgba(#282a36, 1));
    -webkit-font-smoothing: antialiased;
}

.language-selector{
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    z-index: 100;
}

.language-selector__button{
    background: none;
    border: none;
    color: #f8f8f2;
    font-size: 1.6rem;
    font-family: 'Bebas Neue', cursive;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.language-selector__button:hover{
    color: #ff5555;
    transition: all 0.3s ease-in-out;
}

.language-selector__button--active{
    color: #ff5555;
}



.download {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__row {
        display: flex;
        flex-direction: column;
        margin-top: 2em;

        @media (min-width: 35em) {
            flex-direction: row;
        }
    }

    &__title,
    &__subtitle {
        color: mix(#f8f8f2, $color-primary, 90%);
        text-align: center;
    }

    &__title {
        font-weight: bold;
        font-family: "Bebas Neue", sans-serif;
        text-transform: uppercase;

        letter-spacing: 0.1em;
        margin: 0.5em 0 0;
        animation: fadeSlideDown 1s cubic-bezier(0.55, 0.15, 0.45, 0.85);

        @media (min-width: 50em) {
            font-size: 4vw;
        }
    }

    &__subtitle {
        font-weight: 200;
        margin: 0 0 0em;
        animation: fadeSlideUp 1s cubic-bezier(0.55, 0.15, 0.45, 0.85);

        @media (min-width: 50em) {
            font-size: 2vw;
        }
    }
}

.phone {
    display: block;
    width: 50%;
}

.icon {
    height: 2.5rem;
    display: inline-block;
    margin-right: 2rem;
}

span {
    display: inline-block;
}

.app-icon {
    position: relative;
    width: 10rem;
    height: 10rem;
    border-radius: calc(10rem / 57rem) * 10rem;
    
    box-shadow: 0 14px 33px rgba($color-primary, 0.2);
    animation: fadeSlideUpSlow 8s cubic-bezier(0.55, 0.15, 0.45, 0.85);


}

.icon-link {
    min-width: 18rem;
    background: $color-primary;
    display: flex;
    align-items: center;
    margin: 1rem;
    padding: 2rem 2.5rem 2rem 2rem;
    text-decoration: none;
    border-radius: 0.5rem;
    color: #fff;
    animation: turnUp 0.3s cubic-bezier(0.55, 0.15, 0.45, 0.85);
    transition: box-shadow 0.2s cubic-bezier(0.55, 0.15, 0.45, 0.85);

    &__eyebrow {
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        display: block;
    }

    &__source {
        font-size: 2rem;
        line-height: 1;
    }

    svg {
        fill: #fff;
    }

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        box-shadow: 0 14px 33px rgba($color-primary, .09);


        svg {
            fill: #fff;
        }
    }

    &--apple {

        &:hover,
        &:focus,
        &:active {
            background: #F79A04;
            transition: background 0.2s cubic-bezier(0.55, 0.15, 0.45, 0.85);
        }
    }

    &--google {

        &:hover,
        &:focus,
        &:active {
            background: #F79A04;
            transition: background 0.2s cubic-bezier(0.55, 0.15, 0.45, 0.85);
        }
    }
}

@keyframes turnUp {
    from {
        transform: scale(0.9) rotate(3deg) translate3d(0, 10%, 0);
    }
}

@keyframes fadeSlideUp {
    from {
        opacity: 0.8;
        transform: translate3d(0, 3%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeSlideDown {
    from {
        opacity: 0.8;
        transform: translate3d(0, -3%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeSlideUpSlow {
    from {
        opacity: 0.8;
        transform: translate3d(0, 15%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;
    // Make sure video has enough space on the bottom
    margin-bottom: 2rem;



    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        

    }



}

.demo-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    display: block;
    margin-bottom: 1rem;
    color : #fff;
    font-family: 'Bebas Neue', cursive;
}





